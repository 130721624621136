
#banner {
    body.is-preload & {
        .logo {
            transform: none;
            opacity: 1;
        }

        h2 {
            opacity: 1;
            transform: none;
            filter: none;
        }

        p {
            opacity: 1;
            transform: none;
            filter: none;
        }
    }
}